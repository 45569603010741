<template lang="pug">
  .row
    .col-md-12
      .component-card
        v-badge(:value="5")
          v-btn Hello

        .mx-3

        v-badge(:value="2")
          v-btn(fab) ok

      .component-card
        v-tabs(:options="options")
          template(
            slot="item"
            slot-scope="{ active, item }")
            v-badge(
              :show="active"
              :value="item.count")
              v-tab-item(:active="active")
                | {{ item.title }}
</template>

<script>
export default {
  name: 'VBadgeDemo',

  data: () => ({
    options: [
      {
        title: 'Red',
        color: '#f44336',
        count: 4
      },
      {
        title: 'Green',
        color: '#4CAF50',
        count: 5
      },
      {
        title: 'Purple',
        color: '#9C27B0',
        count: 9
      },
      {
        title: 'Blue',
        color: '#3F51B5',
        count: 13
      }
    ]
  })
}
</script>

<style lang="pug">
</style>
